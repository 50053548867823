import search from "@/services/search.service";

export default {
    data() {
        return {
            promptQuery: {
                'rules': [],
                'params': {
                    'text': '',
                    'rewritten_query': '',
                    'context': '',
                    'exclusions': [],
                },
            },

            aiText: "",
            aiLoading: false,
            suggestedAiParams: null,
            geminiAIModel: false,
        };
    },

    computed: {
        promptRules() {
            let rules = [];

            if (this.promptQuery.params.text) {
                let text = this.promptQuery.params.text;

                this.promptQuery.rules.forEach((rule, index) => {
                    text = text.replace(new RegExp(`\\b${rule.value}\\b`, 'i'), `{group-${index}}`);
                });

                let template = "(\\{group-\\d+\\})";
                let parts = text.split(new RegExp(template, 'g'));

                let regexp = /\{group-\d+}/;
                let hasGroups = [];

                parts.forEach(part => {
                    if (regexp.test(part)) {
                        let index = parseInt(part.match(/\d+/)[0]);

                        rules.push(this.promptQuery.rules[index]);
                        hasGroups.push(index);
                    } else {
                        rules.push({
                            name: 'word',
                            value: part
                        });
                    }
                });

                this.promptQuery.rules.forEach((rule, index) => {
                    if (!hasGroups.includes(index)) {
                        rules.push(rule);
                    }
                })
            }

            return rules;
        }
    },

    methods: {
        getAIQueryParams() {
            this.suggestedAiParams = null;
            this.elasticQuery = null;

            if (this.aiText) {
                this.aiLoading = true;

                search.getAIQueryParams(this.aiText, this.geminiAIModel ? 'GeminiAI' : 'OpenAI').then(response => {
                    this.suggestedAiParams = response.data;
                    this.suggestedAiParams.text = this.aiText;
                }).finally(() => {
                    this.aiLoading = false;
                })
            } else {
                this.currentPage = 1;
                this.$bvModal.hide('ai-text-modal');
                this.getList();
            }
        },

        applyAIQueryParams(type) {
            this.notWatchQuery = true;
            this.notWatchFilters = true;
            this.query.rules = [];
            this.promptQuery.rules = [];
            let id = 0;

            this.promptQuery.params = {
                text: this.suggestedAiParams.text,
                rewritten_query: this.suggestedAiParams.rewritten_query,
                context: this.suggestedAiParams.context,
                exclusions: this.suggestedAiParams.exclusions,
            };

            if (this.suggestedAiParams.categories.length) {
                this.suggestedAiParams.categories.forEach(category => {
                    this.updateFilter('categories', [category]);
                    this.updateFilterRule('categories');
                })

                id += this.suggestedAiParams.categories.length;
            }

            if (this.suggestedAiParams.locations.length) {
                this.updateFilter('locations', this.suggestedAiParams.locations.map(item => item.value));
                this.updateFilterRule('locations');
                ++id;
            }

            let filters = {
                fundingSeries: 'funding_types',
                fundingRange: 'funding_ranges',
                revenueRange: 'revenue_ranges',
                lastFundingDate: 'last_funding_date',
                employeeCount: 'employees_count',
            }

            for (let filterType in filters) {
                let filterValues = this.suggestedAiParams[filters[filterType]];

                if (filterValues.length) {
                    this.updateFilter(filterType, filterValues);
                    this.updateFilterRule(filterType);
                    ++id;
                }
            }

            let rules = [];

            for (let mainWord in this.suggestedAiParams.keywords) {
                ++id;

                rules.push({
                    "id": id,
                    "label": "Keyword",
                    "name": "keyword",
                    "value": mainWord,
                    "valueAlias": mainWord,
                    "negative": false,
                    "groupWords": this.suggestedAiParams.keywords[mainWord],
                    "matchCount": 1,
                })
            }

            if (type === 'keywords') {
                this.query.rules.push({
                    'condition': 'OR',
                    'rules': rules
                })
            } else {
                this.promptQuery.rules = rules;

                this.suggestedAiParams.exclusions.forEach(exclusion => {
                    ++id;

                    this.promptQuery.rules.push({
                        "id": id,
                        "label": "Keyword",
                        "name": "keyword",
                        "value": exclusion,
                        "valueAlias": exclusion,
                        "negative": true,
                        "groupWords": [],
                        "matchCount": 1,
                    });
                });
            }

            this.$bvModal.hide('ai-text-modal');
            this.currentPage = 1;

            this.$nextTick(() => {
                this.notWatchQuery = false
                this.notWatchFilters = false
            })

            if (!this.suggestedAiParams.locations.filter(item => !this.filtersOptions['locations'].some(option =>option.value === item.value)).length) {
                // If filter has missing locations, list would be automatically updated after locations loading
                this.getList();
            }
        },
    }
}
