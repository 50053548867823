export function getCurrentRuleLevel(query, rule, inGroup = false, field = 'id') {
    var index = {}

    for (let i in query.rules) {
        let item = query.rules[i];

        if (inGroup) {
            if (item === rule) {
                index = {'level_1': i, count: -1};
                break;
            }
        } else {
            if (typeof item.id !== 'undefined') {
                if (item[field] === rule[field]) {
                    index = {'level_1': i, 'count': 1};
                    break;
                }
            } else {
                if (typeof item.condition !== 'undefined') {
                    for (let j in item.rules) {
                        let elem = item.rules[j];

                        if (typeof elem[field] !== 'undefined') {
                            if (elem[field] === rule[field]) {
                                index = {'level_1': i, 'level_2': j, 'count': 2};
                                break;
                            }
                        }
                    }
                }
            }
        }
    }

    return index;
}

export function getCurrentRuleLevelByField(query, rule, inGroup = false) {
    return getCurrentRuleLevel(query, rule, inGroup, 'name');
}

export function getLastId(query) {
    return query.rules.reduce((lastId, rule) => {
        if ('condition' in rule) {
            lastId = rule.rules.reduce((subLastId, subRule) => {
                if (subRule.id > subLastId) {
                    subLastId = subRule.id;
                }

                return subLastId;
            }, lastId);
        } else if (rule.id > lastId) {
            lastId = rule.id;
        }

        return lastId;
    }, 0);
}

export function deleteRule(query, rule, level) {
    if (!level) {
        level = getCurrentRuleLevel(query, rule);
    }

    // Level 1
    if (level['count'] === 1) {
        query.rules.splice(level['level_1'], 1);
    }

    // Level 2
    if (level['count'] === 2) {
        const subRulesCount = query.rules[level['level_1']].rules.length;

        if (subRulesCount > 2) {
            query.rules[level['level_1']].rules.splice(level['level_2'], 1);
        } else {
            query.rules[level['level_1']].rules.splice(level['level_2'], 1);
            const remainingRule = query.rules[level['level_1']].rules[0];
            query.rules.splice(level['level_1'], 1, remainingRule);
        }
    }
}

export function invertRule(query, rule) {
    let level = getCurrentRuleLevel(query, rule);

    // Level 1
    if (level['count'] === 1) {
        query.rules[level['level_1']].negative = !query.rules[level['level_1']].negative;
    }

    // Level 2
    if (level['count'] === 2) {
        query.rules[level['level_1']].rules[level['level_2']].negative = !query.rules[level['level_1']].rules[level['level_2']].negative;
    }
}

export function getNextRuleLevel(query, rule, level) {
    let rules = query.rules;
    let currentIndex = level['level_1'];
    let nextIndex = currentIndex + 1;
    let nextLevel = {'level_1': nextIndex, 'count': 1};

    // Check if current is inside group
    if (level['count'] === 2) {
        let secondLevelNextIndex = level['level_2'] + 1;
        // First search inside current group
        if (typeof rules[currentIndex].rules[secondLevelNextIndex] != 'undefined') {
            Object.assign(nextLevel, {
                'level_1' : currentIndex,
                'level_2' : secondLevelNextIndex,
                'count': 2
            });

            return nextLevel;
        }
    }

    if (typeof rules[nextIndex] != 'undefined') {
        if (typeof rules[nextIndex].condition != 'undefined') {
            Object.assign(nextLevel, {'level_2' : 0, 'count': 2});
        }
    } else {
        if (typeof rules[currentIndex].condition != 'undefined') {
            let nextSecondLevel = level['level_2'] + 1;
            Object.assign(nextLevel, {
                'level_1' : currentIndex,
                'level_2' : nextSecondLevel,
                'count'   : 2
            });
        }
    }

    return nextLevel;
}
