<template>
    <div class="key__filter">
        <span class="key__filter__wrapper" v-for="(rule, index) in rules" :key="index">
            <span v-if="rule.name === 'word'">
                {{ rule.value }}
            </span>

            <span v-else class="key__filter__tag" :class="{group__rule: typeof rule.groupWords !== 'undefined' && rule.groupWords.length > 0}" >
                <EquationRule
                    :rule="rule"
                    v-on:delete-rule="deleteRule"
                    v-on:edit-group="editGroup"
                    v-on:invert-rule="invertRule"
                />
            </span>
            &nbsp;
        </span>
    </div>

</template>

<script>
    import EquationRule from './EquationRule.vue'

    export default {
        name: 'EquationBar',

        props : {
            rules: Array,

            group: {
                type: Boolean,
                default: false,
            },

            readOnly: {
                type: Boolean,
                default: false,
            },
        },

        components: {
            EquationRule,
        },

        data: function() {
            return {

            };
        },

        methods: {
            deleteRule: function(rule) {
                this.$emit('rule-deleted', rule, true);
            },

            invertRule: function(rule) {
                this.$emit('invert-rule', rule, true);
            },

            editGroup: function(rule) {
                this.$emit('edit-group', rule, true);
            },
        }
    }
</script>
